import React, { useState } from 'react'
import { Link } from 'gatsby'

import { MdThumbUp, MdCheckBox, MdVisibility, MdVisibilityOff, MdVerifiedUser, MdShoppingCart, MdRemoveShoppingCart, MdModeEdit } from "react-icons/md";

/** @jsx jsx */
import { jsx, Button } from 'theme-ui'


const Product = (props) => {
    const { 
        level,
        id,
        levelName,
        title,
        price,
        included,
        benefits,
        showPrice,
        defaultOpen,
        children,
        cart,
        toggleCartItem,
        video_uid,
    } = props

    const [open, setOpen] = useState(defaultOpen || false)

    // console.log('DEFAULT OPEN', defaultOpen)

    return (
        <div sx={{
            bg: 'background',
            px: 4, py: 4,
            mb: 4,
            borderRadius: 1,
            boxShadow: 0,
            position: 'relative',
            overflow: 'hidden',
        }} id={id}>
            <div sx={{
                position: 'absolute',
                top: '0px', left: '0px',
                width: theme => `${theme.space[2] + ((parseInt(level) - 1) * 25)}%`,
                height: '5px',
                content: '" "',
                display: 'block',
                background: theme => `linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.secondary})`,
            }}></div>

            <h2 sx={{
                mt: 0, mb: 3,
                fontFamily: 'heading', fontSize: [3, 3, 4],
            }}>
                {` `}<span sx={gradient}>{title[0]}</span>
                {` `}{title[1]}
            </h2>

            <div sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                {!defaultOpen && <button 
                    onClick={() => setOpen(prevState => !prevState)}
                    sx={{
                        px: 3, py: 2, mb: [2, 0, 0],
                        color: 'background', bg: open ? 'grey' : 'limegreen',
                        textTransform: 'uppercase', fontFamily: 'body', fontWeight: 'bold', fontSize: 2,
                        border: 'none', borderRadius: 0,
                        // background: theme => `linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.secondary})`,
                        svg: {
                            mb: '-2px', 
                        },
                        ':hover': {
                            cursor: 'pointer',
                        },
                        ':focus': {
                            outline: 'none',
                        }
                    }}
                >
                    {open ? <span><MdVisibilityOff sx={{mr: 2}} /> Réduire</span> : <span><MdVisibility sx={{mr: 2,}}/> Voir plus</span>}
                </button>}

                {level !== '1' &&
                    <div sx={{
                        textTransform: 'uppercase',
                        fontFamily: 'body', fontWeight: 'heading', fontSize: 1,
                        py: 2, px: 3, mb: [2, 0, 0],
                        borderRadius: 3,
                        color: 'background', bg: 'primary', 
                        display: 'inline-block',
                    }}>
                        Add-On {parseInt(level) - 1} | <MdVerifiedUser sx={{mb: '-2px'}} /> {levelName}
                    </div>
                }
            </div>

            {open && 
            
                <div>
                    {children}
    
                    <div sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        div: {
                            bg: 'muted',
                            borderRadius: 1,
                            width: ['100%', '100%', '48%'],
                            p: 3,
                        },
                        h4: {
                            mt: 0, mb: 2,
                            color: 'grey', textTransform: "uppercase",
                            letterSpacing: '2px', fontSize: 1,
                            svg: {
                                mb: '-2px',
                                color: 'limegreen',
                            }
                        },
                        ul: {
                            m: 0,
                            pl: 3,
                        }
                    }}>
                        <div sx={{
                            mb: [3, 3, 0],
                        }}>
                            <h4><MdCheckBox /> Inclus</h4>
                            {included}
                        </div>
                        <div>
                            <h4><MdThumbUp /> Avantages</h4>
                            {benefits}
                        </div>
                    </div>
    
                    <div>
                        <Button 
                            sx={{
                                mr: 3, 
                                bg: cart[level] ? 'primary' : 'limegreen',
                                cursor: 'pointer',
                            }}
                            className="button"
                            data-button={`${cart[level] ? "remove" : "add"}--product`}
                            data-product={id}
                            onClick={() => {
                                toggleCartItem(level)
                            }}
                        >
                            {cart[level] ? <React.Fragment><MdRemoveShoppingCart /> Enlever </React.Fragment> : <React.Fragment><MdShoppingCart /> Ajouter</React.Fragment>}
                        </Button>

                        {showPrice && <div sx={{
                            display: 'inline-block',
                            mt: 4,
                            // borderRadius: 3, p: 3, border: '2px solid limegreen',
                            color: 'limegreen', fontWeight: 'bold',
                            fontSize: 3,
                        }}>
                            Prix : {price}
                        </div>}
                    </div>

                    {cart[level] && <div
                        sx={{
                            mt: 2,
                            backgroundColor: "#d4ffd4",
                            p: 3, 
                            borderRadius: 1,
                            "a": {
                                textTransform: "uppercase",
                                color: "white",
                                display: "inline-block",
                                fontWeight: "bold",
                                backgroundColor: "limegreen",
                                px: 2, py: 1,
                                borderRadius: 0,
                                textDecoration: "none", 
                                mt: 2,
                            }
                        }}
                        >✅ Ajoutez d'autres options ci-dessous ou finalisez votre commande avec le formulaire en bas de page : <a href="#contact" title="Commander le pack">🚀 Aller au formulaire de commande !</a>
                    </div>}

                    {video_uid && video(video_uid)}
                </div>
            }
        </div>
    )
}

export default Product

const gradient = {
    background: theme => `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
}

const video = (uid) => {
    return (
        <div 
            sx={{
                position: 'relative',
                width: '100%', height: '0', pb: '56.25%', mt: 3,
                borderRadius: 0, overflow: "hidden",
                boxShadow: 0,
                iframe: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }
            }}
        >
            <iframe 
                src={`https://www.youtube.com/embed/${uid}`}
                title="YouTube video player" frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    )
}