import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Product from '../components/product'
import Form from '../components/form'

/** @jsx jsx */
import { jsx, Container } from 'theme-ui'

import { MdMessage } from "react-icons/md"
// import Button from '../components/button'

const ReferencementPage = () => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle || "notre site"
    const showPrice = true
    const filteredProducts = products().filter(product => product.level !== '1').map(product => ({
        id: `${product.id}`, 
        level: `${product.level}`, 
        name: product.levelName,
        price: product.price,
    }))

    const [cart, setCart] = useState({})

    const toggleCartItem = id => setCart( prevCart => ({
        ...prevCart,
        [id]: !prevCart[id],
    }) )

    return (
        <Layout>
            <SEO title={`Référencement - Obtenir ou améliorer son listing sur ${siteName}`} />

            <Container>
                <div sx={{
                    py: 4,
                    mb: 5,
                }}>    
                    <h1 sx={{
                        fontFamily: 'heading', fontWeight: 'heading',
                        fontSize: [5, 5, 8],
                        lineHeight: 1,
                        mb: 0,
                    }}>
                        Votre <span sx={gradient}>Référencement</span>
                    </h1>
                    <h2 sx={{
                        fontFamily: 'body', fontWeight: 'heading', fontSize: 5,
                    }}>
                        Obtenir ou améliorer son listing sur <span sx={{color: 'grey'}}>{siteName}</span>
                    </h2>
                    <p sx={{fontSize: 3,}}>Nous sommes une plateforme de référencement local premium et spécialisée. Accédez à une exposion immédiate et qualitative aux audiences en recherche de vos services. Référencez votre entreprise avec une fiche de base, ou présentez vos services avec une page premium complète. Boostez votre listing pour apparaître en premières positions. <b>Offrez à votre entreprise la visibilité locale & ciblée qu'elle mérite.</b></p>

                    {/* <Button className="button" data-button="contact-anchor" link={"#contact"}><b>DEMANDER UNE OFFRE</b></Button> */}
                </div>

                <h2>👉 Pour commencer... activez votre fiche</h2>
                {products(siteName).map((product, index) => (
                    <>
                        {index === 1 && (
                            <h2 sx={{
                                mt: 6,
                            }}>🔥 Puis, les Add-Ons (optionnels) pour améliorer votre visibilité</h2>
                        )}

                        {index === 6 && (
                            <h2 sx={{
                                mt: 6,
                            }}>💎 Et sinon, pour tout avoir d'un seul coup !</h2>
                        )}

                        <Product 
                            key={`${product.levelName}-${product.level}`}
                            showPrice={showPrice}
                            id={product.id}
                            level={product.level}
                            levelName={product.levelName}
                            title={product.title}
                            price={product.price}
                            included={product.included}
                            benefits={product.benefits}
                            // defaultOpen={product.level !== '1' ? null : true}
                            defaultOpen={true}
                            cart={cart}
                            toggleCartItem={toggleCartItem}
                            video_uid={product.video_uid}
                        >
                            {product.children}
                        </Product>
                    </>
                ))}

                <div id="contact"
                    sx={{
                        mt: 6,
                        bg: 'background',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 0,
                    }}
                >
                    <Form 
                        products={filteredProducts}
                        cart={cart}
                        toggleCartItem={toggleCartItem}
                    />
                </div>

                <a href="#contact" className="button" data-button="contact-bubble">
                    <div sx={{
                        position: 'fixed', bottom: '10px', left: '10px',
                        p: 3, borderRadius: '100px', lineHeight: '1',
                        bg: 'primary',
                        display: 'flex', justifyContent: "center", alignItems: "center",
                        cursor: 'pointer',
                    }}>
                        <MdMessage sx={{fontSize: 5, color: "white"}}/>
                    </div>
                </a>

                <div sx={{
                    mt: 6,
                }}>
                    <h2>Des questions (F.A.Q.) ?</h2>
                    <h3>1 - Est-ce que je vais devoir payer plusieurs fois comme sur les autres plateformes (abonnements mensuels ou annuels) ?</h3>
                    <p>La fiche de base et la fiche premium ainsi que l'article publi-rédactionnel sont des <b>paiements uniques, pas d'abonnement ici</b> - votre contenu est sur la plateforme sans jamais devoir payer pour un renouvellement. Vous avez droit à une mise à jour gratuite par an. Nos autres offres comme le boost listing (pour apparaître dans les premières positions), ou bien les bannières publicitaires, sont elles à définir en fonction de la durée d'exposition que vous souhaitez - quand les jours sont écoulés, vous pouvez cependant choisir de nous demander d'étendre le boost encore un peu plus (pour la rentrée par exemple, pour Noël, etc).</p>
                    
                    <h3>2 - Est-ce que votre plateforme est bonne pour mon référencement SEO local en Suisse romande ?</h3>
                    <p>Oui ! Ouvrir une fiche "Base" sur la plateforme vous donne une chance de plus d'apparaître sur Google en plus de votre site. <b>Vous faites un réel investissement SEO</b> en prenant en plus la page "Premium" qui contient plus de contenu (indexé par Google) et sera donc plus haut dans les résultats de recherche. Idem avec notre offre de publi-rédactionnel, où nous vous accompagnons aussi pour maximiser le potentiel de référencement local de l'article.</p>
                    
                    <h3>3 - Combien de contacts / leads allez-vous m'apporter ?</h3>
                    <p>Notre plateforme est clairement positionnée 1. <b>Qualitative</b> - locale (Suisse romande uniquement) et ciblée (votre industrie uniquement) - et 2. <b>Premium</b> - nous vous accompagnons dans la réalisation de la meilleure fiche possible pour convaincre les internautes. Nous prenons donc le contre-pied total des plateformes en libre accès à abonnement qui virtuellement on un trafic conséquent (car ces plateformes sont généralistes) mais ne vous donneront un trafic qualifié et ciblé qu'en payant (avec abonnements). Nous ne nous engageons donc pas sur "un nombre de clics" / "une quantité de leads" / "un nombre de vues", mais vous permettons d'obtenir une exposition avec des contenus de qualité auprès de votre audience locale. Vous avez aussi la responsabilité de votre succès : une fiche premium avec boost, un article de publi-rédactionnel et une bannière maximisent par exemple votre exposition - personne ne peut vous rater (voir le "PACK TOUT COMPRIS" ci-dessous). Il est aussi important de nous fournir les bonnes informations (et nous vous accompagnons avec professionnalisme et expertise) afin que nous puissions vous faire la fiche premium la plus convaincante et informative, tout en utilisant de beaux médias (images, vidéos...).</p>

                    <h3>4 - Quel retour sur investissement permettez-vous ?</h3>
                    <p>Dès l'ouverture d'une fiche sur notre plateforme (49 CHF), vous pourrez savoir si vous utilisez Google Analytics (GA4) le nombre de visites générées par votre fiche. Si vous prenez l'extension de fiche "PREMIUM", vous pourrez ajouter un module "promotions" permettant de mettre en avant des offres spéciales - en créant un code promo spécifique pour la plateforme, vous saurez encore mieux lorsque vous serez contacté.e que la personne vient depuis notre plateforme (si votre code promo n'est utilisé qu'ici). A partir du "PACK TOUT COMPRIS", nous proposons un suivi plus complet des statistiques de votre fiche, avec la possibilité de nous demander les rapports de vues (et durée de vue) ainsi que tous les clics vers vos destinations (email, phone, map, réseaux sociaux). Nous avons ajouté des UTMs (liens de tracking) à vos liens de fiche, afin que vous puissiez découvrir le trafic referral "localgraph" au fil du temps, et réagir en conséquence si vous souhaitez plus (premium, boost ...) !</p>
                    
                    <h3>5 - C'est quoi le "PACK TOUT COMPRIS" ?</h3>
                    <p>Le "PACK TOUT COMPRIS" représente <b>en un seul pack le meilleur de ce que notre plateforme peut vous apporter</b>. Il vous permet d'obtenir la fiche la plus complète possible (BASE+PREMIUM), vient y ajouter un article de publi-rédactionnel pour présenter une réalisation concrète sous un angle "behind the scenes" (ou autre concept à discuter). Nous venons ensuite booster votre visibilité par un positionnement en haut de listing, et un bannière publicitaire. Tout ceci avec notre accompagnement et suivi afin de maximiser votre succès !</p>
                    
                    <h3>6 - Je veux aller plus loin dans ma présence digitale, que proposez-vous ?</h3>
                    <p>Si vous avez décidé d'investir dans votre présence digitale, et que vous avez déjà utilisé tout ce que notre plateforme peut vous apporter (avec le "PACK TOUT COMPRIS"), nous avons des offres d'accompagnement par la société mère : le "Digital Lab" (voir plus sur www.lyketil.com). Contactez-nous pour un suivi personnalisé de votre digital, avec une <b>approche complètement dédiée d'agence créative et digitale</b> - mais toujours avec notre approche locale et d'accompagnement de proximité</p>
                </div>
            </Container>
      </Layout>
    )
}

export default ReferencementPage

const pageQuery = graphql`
  query referencementQuery {
    site {
      siteMetadata {
        siteTitle
        siteDesc
        siteTagline
      }
    }
  }
`

const gradient = {
    background: theme => `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
}

const products = (siteName) => ([
    {
        key: '1',
        id: 'base',
        level: '1',
        levelName: 'BASE',
        title: ['Apparaître', `sur ${siteName} avec une fiche de "Base"`],
        price: '49.- CHF HT (Paiement Unique)',
        included: [
            <ul key='included-base'>
                <li>Votre entreprise en tant que "vérifiée" dans le listing</li>
                <li>Coordonnées, liens, logo, pitch, réseaux sociaux</li>
                <li>1 mise à jour gratuite par an (puis 25 CHF /modif.)</li>
            </ul>
        ],
        benefits: [
            <ul key='included-benefits'>
                <li>Apparaissez dans les résultats de recherche</li>
                <li>Bonnes pratiques : contenus validés par nos expert.e.s</li>
                <li>Améliorez votre fiche avec nos "add-ons" (ci-dessous)</li>
            </ul>
        ],
        children: [
            <div key='base-listing'>
                <p>Vous souhaitez apparaître sur le listing de {siteName} pour être vu et trouvé dans les recherches ? C'est très simple, <b>il vous suffit remplir le formulaire ci-dessous</b> en nous fournissant les informations nécessaires.</p>
            </div>
        ],
    },
    {
        key: '2',
        id: 'premium',
        level: '2',
        levelName: 'PREMIUM',
        title: ['Présentez-vous', 'avec une fiche "Premium" complète et customisable'],
        price: '150.- CHF HT (Paiement Unique)',
        video_uid: "fSCxySZ17x0",
        included: [
            <ul key='included-premium'>
                <li>La fiche "base" améliorée par des modules de contenu</li>
                <li>Présentez votre activité sous son meilleur jour</li>
                <li>1 mise à jour gratuite par an (puis 25 CHF /modif.)</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-premium'>
                <li>Modules : donne plus d'info et convainc mieux</li>
                <li>Image de couverture : plus visible dans le listing</li>
                <li>Vous investissez dans votre référencement (SEO)</li>
            </ul>
        ],
        children: [
            <p key='premium'>Nous améliorons votre fiche de "base" sur {siteName} en y ajoutant des modules "Premium" pour présenter votre activité de manière claire, moderne et design. Notre équipe s'occupe de tout, et vous guide pour présenter efficacement votre activité sur votre landing page.</p>
        ],
    },
    {
        key: '3',
        level: '3',
        id: 'listing-boost',
        levelName: 'LISTING BOOST',
        title: ['Boostez', 'votre visibilité dans les 1ers résultats'],
        price: 'Dès seulement 2.- CHF HT par jour',
        // video_uid: "0bL83yCdONQ",
        included: [
            <ul key='included-boost'>
                <li>Mise en avant en haut de première page dans les premières positions</li>
                <li>Mise en avant en haut des listings cantonaux</li>
                <li>Mise en avant en haut des listings filtrés</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-boost'>
                <li>Maximisez votre visibilité sans attendre</li>
                <li>Soyez placé immédiatement en haut des résultats</li>
                <li>Montrez votre professionnalisme et dynamisme par rapport aux autres entreprises</li>
            </ul>
        ],
        children: [
            <p key='boost'>Vous souhaitez que votre profil apparaisse dans les premiers résultats sur {siteName} ? Nous vous permettons de mettre en avant votre profil dans les listings pour vous assurer un maximum de visibilité lorsque vous en avez besoin. Durée minimale : 15 jours (soit 30.- CHF reconductibles)</p>
        ],
    },
    {
        key: '4',
        level: '4',
        id: 'ad-banner',
        levelName: 'BANNIÈRE PUBLICITAIRE',
        title: ["Attirez l'attention", " par une bannière publicitaire"],
        price: 'À partir de 5.- CHF / jour',
        included: [
            <ul key='included-banner'>
                <li>Bannière en header de site (pages : accueil et cantons)</li>
                <li>Votre créa en format horizontal 1000 x 120px (JPG)</li>
                <li>Un lien vers l'URL de votre choix</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-banner'>
                <li>Une visibilité immédiate auprès de tous les internautes</li>
                <li>Vous n'avez pas besoin de fiche pour apparaître</li>
                <li>Communication ponctuelle à prix dégressif (sur durée)</li>
            </ul>
        ],
        children: [
            <p key="banner">Vous souhaitez vous faire remarquer avec un visuel fort sur {siteName}, nous vous mettons à disposition un emplacement publicitaire en première ligne, vu de toutes et tous. Nous réalisons la créa pour vous si vous ne disposez pas des outils nécessaires. Durée minimale : 5 jours (soit 25.- CHF reconductibles)</p>
        ],
    },      
    {
        key: '5',
        level: '5',
        id: 'storytelling-boost',
        levelName: 'ARTICLE PUBLI-REDAC',
        title: ['Humanisez', 'votre entreprise avec le publi-rédactionnel'],
        price: 'À partir de 90.- CHF / post',
        included: [
            <ul key='included-story'>
                <li>Mise en avant d'un blog en homepage</li>
                <li>Publication possible sur vos réseaux sociaux</li>
                <li>Ajout du reportage sur votre page</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-story'>
                <li>Faites-vous connaitre pour votre expertise</li>
                <li>Obtenez encore plus de visibilité</li>
                <li>Présentez-vous sous un angle plus personnel</li>
            </ul>
        ],
        children: [
            <p key="storytelling">Afin d'avoir un maximum d'impact auprès des visiteurs de {siteName}, nous vous permettons de bénéficier d'un reportage publi-rédactionnel (en texte images et vidéo) afin de présenter votre activité sous un angle plus immersif (type reportage / documentaire). Il peut s'agir d'un blog où vous partagez votre expertise et votre vision ou vos conseils. Mais il peut aussi s'agir d'un reportage immersif réalisé par notre équipe, si vous prenez aussi le pack MEDIA PRO : nous réalisons un mini-docu pour présenter votre entreprise dans un angle journalistique.</p>
        ],
    },
    // {
    //     key: '6',
    //     level: '6',
    //     id: 'media-pro',
    //     levelName: 'MEDIA PRO',
    //     title: ['Différenciez-vous', 'avec de beaux contenus multimédia'],
    //     price: 'À partir de 390.- CHF HT',
    //     // video_uid: "4p-tPxZf1wY",
    //     included: [
    //         <ul key='included-mediapro'>
    //             <li>Vidéo de présentation de votre activité</li>
    //             <li>Photos de présentation (activité, équipe)</li>
    //             <li>Mise à disposition pour site web, réseaux sociaux, etc</li>
    //         </ul>
    //     ],
    //     benefits: [
    //         <ul key='benefits-mediapro'>
    //             <li>Vidéo professionnelle de qualité</li>
    //             <li>Humanisez votre présence et convainquez</li>
    //             <li>Différenciez votre fiche par un beau contenu</li>
    //         </ul>
    //     ],
    //     children: [
    //         <p key='media-pro'>Pour améliorer votre page dédiée sur {siteName}, au delà des modules de contenus que notre équipe met en place (voir ci-dessus: ADD-ON 1 - Premium), nous proposons de produire un contenu de niveau professionnel pour présenter votre entreprise en photo, vidéo, et éventuellement caméra 360°. Ces contenus sont aussi utilisables sur vos propres supports et vous appartiennent !</p>
    //     ],
    // },
    {
        key: '6',
        level: '6',
        id: 'full-pack',
        levelName: 'PACK TOUT COMPRIS',
        title: ['Tout-en un', 'pour la visibilité la plus complète'],
        price: 'À partir de 750.- CHF HT',
        // video_uid: "4p-tPxZf1wY",
        included: [
            <ul key='included-fullpack'>
                <li>Votre fiche de base et premium (illimité)</li>
                <li>Votre contenu publi-rédac (illimité)</li>
                <li>Le boost listing et par bannière publicitaire (3 mois)</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-fullpack'>
                <li>La visibilité maximale sur notre plateforme</li>
                <li>Un accompagnement et suivi par un.e expert.e dédié.e</li>
                <li>Pricing compétitif (750.- au lieu de 920.- chf)</li>
            </ul>
        ],
        children: [
            <p key='fullpack'>Vous souhaitez investir dans votre présence sur cette plateforme et ne rien laisser au hasard car vous avez besoin d'une visibilité forte ? Alors choisissez ce pack tout compris pour vous assurer une visibilité inégalée et vous assurez que votre entreprise est vue par 100% des visiteur.ices. Nous vous accompagnons pour tirer le maximum de cette présence.</p>
        ],
    },
])